import { css } from '@emotion/react';
import React from 'react';
import {
  fontSize,
  layout,
  lineHeight,
  marginBottom,
  marginTop,
  mediaQuery,
  padding,
  typographic,
} from '../../../constants/style';

type Props = {
  body: string;
  title: string;
};

export const Content = ({ body, title }: Props) => (
  <div css={styleMain}>
    <h1 css={styleTitle}>{title}</h1>
    <div css={styleBody} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

const styleMain = css`
  max-width: ${layout.postSingleWidth};
  padding: 0 15px;
  margin: 0 auto;
`;

const styleTitle = css`
  font-size: ${fontSize.main};
  max-width: ${layout.postWidth};
  margin-left: auto;
  margin-right: auto;
  ${marginTop(1.5)};
  ${marginBottom(1.5)}
  font-weight: normal;
  text-align: center;
  ${lineHeight(1.65)};

  ${mediaQuery.sm} {
    font-size: calc(${fontSize.main} * 0.7);
  }
`;

const styleBody = css`
  & figure {
    ${marginBottom(1)};

    & blockquote {
      font-style: italic;
      text-align: center;
      margin-top: 0;
      ${padding(1, 0)}

      & p {
        max-width: ${layout.postWidth};
        font-size: calc(${typographic.baseFontSize} * 1.6817);
        margin-top: 0;
        ${marginBottom(1)};
        ${lineHeight(1.5)};
      }
    }
  }

  & a {
    text-decoration: underline;
  }

  & * {
    max-width: ${layout.postWidth};
    margin-left: auto;
    margin-right: auto;
  }

  & img {
    max-width: 100%;
  }
`;
