import { css } from '@emotion/react';
import React from 'react';
import { Content } from './Content';
import { Node } from '../../types';
import { mediaQuery } from '../../constants/style';

type Props = {
  post: Node;
};

export const Post = ({ post }: Props) => {
  const { html } = post;
  const { title } = post.frontmatter;

  return (
    <div css={styleMain}>
      <div>
        <Content body={html} title={title} />
      </div>
    </div>
  );
};

const styleMain = css`
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  ${mediaQuery.sm} {
    width: 95%;
  }
`;
