import * as React from 'react';
import { css } from '@emotion/css';

export const Footer = () => {
  return <div className={styleMain}></div>;
};

const styleMain = css`
  margin: 10%;
`;
